.wrapper {
  position: relative;
}

.menu {
  position: fixed;
  background: var(--white);
  border-radius: 10px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(0, 0, 0, 0.05);
  z-index: 10001;
  width: 200px;
  padding: 8px 0;
}

.backdrop {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.divider {
  display: block;
  margin: 0.5rem 0;
  border-top: 1px solid var(--hairline);
}

.option {
  padding: 8px 16px;
  cursor: pointer;
  color: var(--black);
  display: block;
  text-decoration: none;
}

.option:hover {
  background: var(--whiteHover);
}

.option.disabled {
  cursor: not-allowed;
  opacity: 0.4;
  background: unset;
}
