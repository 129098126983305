.header {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 270px;
  background: var(--white);
  box-shadow: inset -1px 0 0 var(--hairline);
  display: flex;
  flex-direction: column;
  z-index: 1000;
}

.header.bannerActive {
  top: 40px;
}

.innerWrapper {
  padding: 20px 0 30px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex: 1;
}

.brandWrapper {
  text-decoration: none;
  padding: 1rem 30px;
}

.brand {
  height: 20px;
  color: transparent;
  text-indent: -10000px;
  overflow: hidden;
  background: url('./logo.svg') no-repeat left;
  display: block;
}

.rightNav {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.rightNav > * {
  margin-left: 16px;
}

.nav {
  flex: 1;
  margin: 0 0 1rem;
}

.nav li {
  display: block;
}

.link,
.folder {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--black);
  text-decoration: none;
  font-size: 15px;
  transition: all 0.2s;
  line-height: 28px;
  padding-left: 60px;
  margin: 2px 0;
  position: relative;
}

.link:hover,
.link.focussed {
  background: var(--lightestGrey);
  box-shadow: inset -1px 0 var(--hairlineTransparent);
}

.link.active {
  font-weight: 500;
  background: var(--lightestGrey);
  box-shadow: inset -1px 0 var(--hairlineTransparent);
}

.icon {
  position: absolute;
  left: 28px;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.expand {
  margin-left: -20px;
}

.emoji {
  margin: 4px;
}

.depth-1 {
  padding-left: 76px;
}

.depth-1 .icon {
  left: 44px;
}

.depth-2 {
  padding-left: 92px;
}

.depth-2 .icon {
  left: 60px;
}

.depth-3 {
  padding-left: 108px;
}

.depth-3 .icon {
  left: 76px;
}

.depth-4 {
  padding-left: 124px;
}

.depth-4 .icon {
  left: 92px;
}

.depth-5 {
  padding-left: 140px;
}

.depth-5 .icon {
  left: 108px;
}

.depth-6 {
  padding-left: 156px;
}

.depth-6 .icon {
  left: 124px;
}

.depth-7 {
  padding-left: 172px;
}

.depth-7 .icon {
  left: 140px;
}

.depth-8 {
  padding-left: 188px;
}

.depth-8 .icon {
  left: 156px;
}

.depth-9 {
  padding-left: 204px;
}

.depth-9 .icon {
  left: 172px;
}

.depth-10 {
  padding-left: 220px;
}

.depth-10 .icon {
  left: 188px;
}

.label {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.command {
  display: none;
  padding: 0 1rem 0 0.25rem;
  font-size: 13px;
  color: var(--placeholder);
}

.commandPressed .command {
  display: block;
}

.nav ul ul {
  padding-left: 1rem;
  padding-bottom: 1rem;
}

.nav ul ul .link {
  font-size: 16px;
  height: 24px;
}

.toggleButton {
  display: none;
}

.closeButton {
  display: none;
}

.account {
  margin-bottom: 20px;
}

.morphedBadge {
  padding: 0 40px;
}

.footerLinks {
  margin: 0 40px 20px;
  color: var(--navLink);
}

.footerLinks a {
  display: block;
  color: inherit;
  font-weight: 600;
  transition: all 0.2s;
}

.footerLinks a:hover {
  color: var(--navLinkHover);
}

.add {
  margin: 1rem 32px;
}

.search {
  padding: 0.5rem 2rem 1.5rem;
}

.item {
  user-select: none;
}

.activeDrag {
  position: fixed;
  pointer-events: none;
  opacity: 0.3;
}

.dragging {
  pointer-events: none;
}

.dropIndicator {
  position: fixed;
  left: 0;
  pointer-events: none;
  width: 270px;
  border: 1px solid #c200fb;
}

.overflow {
  display: none;
  margin: -4px 12px -4px 4px;
}

.folder:hover > .overflow,
.overflowActive {
  display: block;
}

@media (max-width: 939px) {
  .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: unset;
    transform: translateX(-100%);
    transition: transform 0.2s;
    overflow-y: auto;
  }

  .header.open {
    transform: translateX(0);
  }

  .innerWrapper {
    flex: unset;
    overflow-y: unset;
  }

  .nav {
    flex: unset;
  }

  .account {
    margin-top: 40px;
  }

  .toggleButton {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 48px;
    height: 56px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
    cursor: pointer;
  }

  .toggleButton span {
    display: block;
    width: 16px;
    height: 2px;
    background: var(--black);
    margin: 2px 0;
  }

  .closeButton {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    color: transparent;
    overflow: hidden;
    text-indent: -10000px;
    overflow: hidden;
    width: 25px;
    height: 25px;
    z-index: 1;
  }

  .closeButton:before,
  .closeButton:after {
    content: '';
    display: block;
    position: absolute;
    top: 12px;
    left: 0;
    right: 0;
    border-bottom: 1px solid var(--black);
  }

  .closeButton:before {
    transform: rotate(-45deg);
  }

  .closeButton:after {
    transform: rotate(45deg);
  }
}

@media print {
  .header,
  .toggleButton {
    display: none !important;
  }
}
