.description {
  margin: 1rem 0;
}

.cancelButton {
}

.upgradeButton {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
}

.billingHook {
  margin-top: 1rem;
}

.billingHook p {
  color: var(--midGrey);
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 1rem 0;
}

.shopify p + p {
  margin-top: 0.5rem;
}
