.users {
}

.item {
  margin: 28px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.info {
  flex: 1;
}

.role {
  color: var(--midGrey);
  font-weight: normal;
}

.info h3 {
  font-weight: 500;
}

.info p {
  color: var(--midGrey);
}

.meta {
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
}
