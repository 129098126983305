.wrapper {
  margin: 35px 40px 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header {
  margin: 0;
  flex: 1;
}

.content {
  flex: 1;
}

.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.actions > * + * {
  margin-left: 0.5rem;
}

.content p {
  max-width: 700px;
}

.spacer {
  display: none;
}

.placeholder {
  opacity: 0;
}

@media (max-width: 939px) {
  .wrapper {
    padding: 12px 16px 8px 48px;
    margin: 0;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    background: var(--white);
    z-index: 1;
  }

  h1.header {
    font-size: 22px;
  }

  .actions {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
  }

  .spacer {
    display: block;
    height: 53px;
  }
}
