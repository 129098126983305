.wrapper {
  flex: 1;
  overflow: hidden;
}

.wrapper :global .recharts-cartesian-grid-horizontal line:first-child,
.wrapper :global .recharts-cartesian-grid-horizontal line:last-child {
  display: none;
}

.wrapper :global .recharts-cartesian-grid line {
  stroke: var(--hairlineTransparent);
}

.wrapper
  :global
  .recharts-xAxis
  .recharts-cartesian-axis-tick:first-child
  text {
  text-anchor: start;
}

.wrapper :global .recharts-xAxis .recharts-cartesian-axis-tick:last-child text {
  text-anchor: end;
}
