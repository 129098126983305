.users {
}

.userItem {
  margin: 28px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.userInfo {
  flex: 1;
}

.role {
  color: var(--midGrey);
  font-weight: normal;
}

.userInfo h3 {
  font-weight: 500;
}

.userInfo p {
  color: var(--midGrey);
}

.userMeta {
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
}

.inviteButton {
  display: flex;
  flex-direction: row;
}

.invitedUser h3 {
  color: var(--midGrey);
}
