.loading-expanded {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.loading-icon {
  display: block;
  width: 44px;
  height: 44px;
  background: url('./loader-icon@2x.png') no-repeat center;
  background-size: contain;
  animation-name: spin;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.loading-small .loading-icon {
  width: 22px;
  height: 22px;
}

.loading-inline {
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.25rem;
}

.loading-inline .loading-icon {
  width: 20px;
  height: 20px;
}

/* Full Screen */

.loading-full-screen {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
}

@media (min-width: 940px) {
  .page-wrapper .loading-full-screen {
    left: 270px;
  }
}
