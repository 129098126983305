.planDetail {
}

.price {
  color: var(--primary);
  font-weight: 500;
}

.priceNumber {
  font-size: 48px;
  font-weight: 600;
}

.plans {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 2.5rem 0 2rem;
}

.planDetail {
  text-align: center;
}

.planDetail h3 {
  margin-bottom: 0.5rem;
}

.bulletPoints {
  text-align: left;
}

.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.yearlySwitch {
  margin: 2rem 0 3.5rem;
}

.hideCancel {
  justify-content: center;
}
