.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  padding: 20px;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1004;
  display: flex;
  flex-direction: column;
}

.innerWrapper {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: min-content;
}

.modal {
  width: 100%;
  max-width: 600px;
  background: var(--white);
  padding: 1px 40px;
  border-radius: 10px;
  position: relative;
  z-index: 2;
}

.modalContent {
  margin: 30px 0 34px;
}

.noPad {
  padding: 0;
}

.noPad .modalContent {
  margin: 0;
}

/* Sizes */

.lg {
  max-width: 800px;
}

.xl {
  max-width: 920px;
}

.backdrop {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.header {
  text-align: center;
}

@media (max-width: 939px) {
  .wrapper,
  .innerWrapper {
    display: block;
  }

  .wrapper {
    padding: 0;
    background: var(--white);
  }

  .modal {
    padding: 1px 1rem;
    border-radius: 0;
    max-width: unset;
  }

  .modalContent {
    margin: 1rem 0;
  }

  .wrapper h1 {
    text-align: center;
  }

  .backdrop {
    display: none;
  }
}

@media (min-width: 940px) {
  /* Contained Modal */
  .contained,
  .contained .modal {
    overflow: hidden;
  }

  .contained .innerWrapper {
    max-height: 100%;
    min-height: unset;
  }

  .contained .modal,
  .contained .modalContent {
    height: 100%;
  }
}
