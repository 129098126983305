.card {
  border-radius: 10px;
  display: block;
  margin-bottom: 20px;
  margin-top: 20px;
  padding: 1px 0;
}

.cardSub {
  margin: 19px 0;
}

.bordered {
  box-shadow: inset 0 0 0 1px var(--hairline);
  padding: 1px 38px;
}

.bordered .cardSub {
  margin: 34px 0;
}

.link {
  text-decoration: none;
  color: inherit;
}

.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.actions > * {
  margin-left: 10px;
}

.leftAligned .actions {
  justify-content: flex-start;
}

.leftAligned .actions > * {
  margin-left: unset;
  margin-right: 10px;
}

/* No-Pad */

.noPad {
  padding: 0;
}

.noPad .cardSub {
  margin: 0;
}
