.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.innerWrapper {
  min-height: min-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.content {
  padding: 2rem;
}

.content h1 {
  text-align: center;
}

.trialWarning {
  text-align: center;
  margin-top: 2rem;
  color: var(--midGrey);
}
