.wrapper {
  margin: 18px 0;
  position: relative;
  padding-bottom: 1px;
}

.labelWrapper {
  display: flex;
  align-items: baseline;
}

.input {
  appearance: none;
}

.wrapper label,
.input,
.wrapper select {
  display: block;
  width: 100%;
  flex: 1;
  text-align: left;
  color: var(--midGrey);
}

.wrapper label {
  font-weight: 500;
}

.input,
.wrapper select {
  border: none;
  background: transparent;
  font: inherit;
  color: inherit;
  outline: none;
  line-height: 36px;
  height: 46px;
  font-size: 15px;
  padding: 0 4px 0 15px;
  border-radius: 9px;
}

.xsmall .innerWrapper {
  height: 24px;
}

.xsmall input:hover::-webkit-inner-spin-button {
  height: 24px;
  margin-top: 4px;
}

.xsmall input:focus::-webkit-inner-spin-button {
  height: 24px;
  margin-top: 4px;
}

.small .input,
.small select {
  height: 36px;
  font-size: unset;
  padding: 0 4px 0 12px;
}

.medium .input,
.medium select {
  height: 40px;
}

.wrapper select {
  appearance: none;
  background: url('../../Icon/icons/carret.svg') no-repeat right 8px center;
  cursor: pointer;
}

.wrapper .input::placeholder,
.wrapper.empty select {
  color: var(--placeholder);
}

.rightAlignError {
  white-space: nowrap;
  align-self: flex-end;
  position: relative;
  min-height: 20px;
}

.errorMessage {
  color: var(--red);
  font-weight: 600;
  margin: 5px 0 0;
}

.rightAlignError .errorMessage {
  position: absolute;
  white-space: nowrap;
  right: 0;
}

.innerWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: all 0.1s;
  border: 1px solid var(--hairline);
  border-radius: 10px;
  background: var(--white);
}

textarea.input {
  padding-top: 13px;
  padding-bottom: 13px;
  line-height: 1.36em;
  resize: none;
}

.wrapper.disabled .input,
.wrapper.disabled select,
.wrapper.softDisabled .input,
.wrapper.softDisabled select {
  color: var(--midGrey);
}

.wrapper.disabled .input,
.wrapper.disabled select {
  pointer-events: none;
}

.wrapper.disabled .innerWrapper,
.wrapper.siftDisabled .innerWrapper {
  background: var(--lightestGrey);
}

.wrapper.softDisabled .input:focus,
.wrapper.softDisabled select:focus {
  background: var(--white);
  color: unset;
}

.focussed .innerWrapper,
.error .innerWrapper {
  border-color: var(--hairlineDark);
}

.wrapper.error .innerWrapper {
  border-color: var(--red) !important;
}

.inputWrapper {
  flex: 1;
}

.prefix,
.suffix {
  color: var(--midGrey);
  pointer-events: none;
}

.prefix {
  margin-right: -10px;
  padding-left: 14px;
}

.suffix {
  padding-right: 14px;
}

/* Reduced Padding */

.noPad {
  margin: 0;
}

.attractBelow {
  margin: 18px 0 4px 0;
}

/* Borderless */

.underline .innerWrapper,
.borderless .innerWrapper {
  border-radius: 0;
  border: none;
}

.underline .input,
.borderless .input,
.underline select,
.borderless select {
  padding-left: 0;
  height: 36px;
  border-radius: 0;
}

.underline .suffix,
.borderless .suffix {
  padding-right: 0;
  line-height: 36px;
}

.underline .prefix,
.borderless .prefix {
  padding-left: 0;
  padding-right: 1rem;
  line-height: 36px;
}

/* Underline */
.underline .innerWrapper,
.borderless .innerWrapper {
  border-bottom: 1px solid var(--hairline);
}

/* Transparent */
.transparent .innerWrapper {
  background: transparent;
}

/* Inverted Styles (darkmode) */

.inverted .input,
.inverted select,
.inverted label {
  color: #fff;
}

.inverted .innerWrapper {
  border-color: rgba(255, 255, 255, 0.2);
}

.inverted.focussed .innerWrapper {
  border-color: #fff;
}

.helpText {
  font-size: 12px;
}

.type-radio {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.type-radio .labelWrapper {
  flex: 1;
}

.type-radio label {
  color: #000;
  font-weight: 400;
}

.type-radio .innerWrapper {
  width: 20px;
  height: 20px;
  border: none;
  box-shadow: none;
  margin-right: 8px;
}

.info {
  margin-right: -6px;
}

.arrows {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-self: stretch;
  margin-right: 0.25rem;
}

.suffix + .arrows {
  margin-left: -0.25rem;
}

.arrow {
  flex: 1;
  display: flex;
  flex-direction: column;
  appearance: none;
  border: 0 none;
  background: transparent;
  color: var(--black);
  font: inherit;
  padding: 1px;
  font-size: 15px;
  cursor: pointer;
}

:global(.non-touch) .arrow {
  color: transparent;
  overflow: hidden;
  text-indent: -10000px;
}

.up {
  justify-content: flex-end;
}

.arrowSub {
  background: var(--lightGrey);
  border-radius: 5px;
  width: 20px;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow:hover .arrowSub {
  background: var(--lightGreyHover);
}

.arrow:active .arrowSub {
  background: var(--lightGreyActive);
}

.arrowIndicator {
  display: block;
  border: 4px solid transparent;
  height: 4px;
}

.up .arrowIndicator {
  border-bottom-color: var(--black);
  border-top: none;
}

.down .arrowIndicator {
  border-top-color: var(--black);
  border-bottom: none;
}

.xsmall .arrowSub {
  height: 9px;
  border-radius: 2px;
}

.xsmall .arrowIndicator {
  border-width: 3px;
}

:global(.non-touch) .arrows {
  display: none;
}

:global(.non-touch) .focussed .arrows,
:global(.non-touch) .wrapper:hover .arrows {
  display: flex;
}

.underline .arrows,
.borderless .arrows {
  margin-right: 0;
}

:global(.touch) .arrows {
  flex-direction: row-reverse;
  align-items: stretch;
}

:global(.touch) .arrow {
  padding: 1px 3px;
  justify-content: center;
}

:global(.touch) .arrowSub {
  width: 24px;
  height: 24px;
}

:global(.touch) .arrowIndicator {
  display: none;
}

@media (min-width: 940px) {
  .borderless:not(.error) .innerWrapper {
    border-bottom: none;
  }
}
