.wrapper {
  margin: 18px 0;
}

.noPad {
  margin-top: 0;
  margin-bottom: 0;
}

.wrapper > label {
  color: var(--midGrey);
  font-weight: 500;
}

.wrapper input {
  font: inherit;
}

.disabled {
  color: #aaa !important;
  pointer-events: none;
}

.input {
  position: relative;
}

.errorMessage {
  color: var(--red);
  font-weight: 600;
  margin: 5px 0 0;
}

.multiValueContainer {
  background: #eee;
  border-radius: 6px;
  box-shadow: inset 0 0 0 2px var(--white);
  padding: 2px;
}

.portalContainer > div:before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
