.wrapper {
  color: var(--midGrey);
}

.wrapper a {
  text-decoration: none;
  color: inherit;
}

.wrapper a:hover {
  text-decoration: underline;
}
