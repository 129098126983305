.wrapper {
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
}

.wrapper img {
  display: block;
  width: 100% !important;
  height: 100% !important;
}

.size-lg {
  width: 28px;
  height: 28px;
  min-width: 28px;
  min-height: 28px;
}

.size-md {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
}

.size-sm {
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
}
