.wrapper {
  position: relative;
  display: inline-block;
  margin: 0 2px;
  vertical-align: middle;
  margin-bottom: 2px;
}

.icon {
  height: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.popout {
  background: #fff;
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  width: 250px;
  top: 100%;
  left: 100%;
  z-index: 2;
  color: var(--black);
  font-weight: 400;
  display: none;
}

.wrapper:hover .popout {
  display: block;
}

.popout p + p {
  margin-top: 0.7rem;
}
