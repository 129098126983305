.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.wrapper > p {
  transition: color 0.3s;
  flex: 1;
}

.wrapper > p:first-child {
  text-align: right;
}

.wrapper > p:not(.activeLabel) {
  color: var(--midGrey);
}

.switch {
  width: 36px;
  min-width: 36px;
  max-width: 36px;
  height: 18px;
  border-radius: 9px;
  border: 1px solid var(--hairline);
  position: relative;
  cursor: pointer;
  margin: 0.5rem 0.5rem;
}

.indicator {
  width: 12px;
  height: 12px;
  background: var(--primary);
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
  transition: left 0.3s;
  pointer-events: none;
}

.selected .indicator {
  left: 20px;
}
