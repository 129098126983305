.tabs {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--hairline);
  gap: 1rem;
  justify-content: center;
  position: sticky;
  left: 0;
  top: 1px;
  min-height: 60px;
  z-index: 1004;
  margin-top: -75px;
  pointer-events: none;
}

.tabs button {
  appearance: none;
  border: 0px none;
  font: inherit;
  color: var(--black);
  transition: all 0.2s;
  background: transparent;
  line-height: 28px;
  cursor: pointer;
  margin-bottom: 1px;
  pointer-events: all;
}

.tabs button:hover {
  box-shadow: 0 2px var(--midGrey);
}

.tabs button.active {
  font-weight: 600;
  color: var(--primary);
  box-shadow: 0 2px var(--primary);
}

@media (max-width: 939px) {
  .tabs {
    display: none;
  }
}
