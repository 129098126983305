.wrapper {
  margin: 1rem 0;
}

.textarea {
  font: inherit;
  padding: 1rem;
  display: block;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  border: 1px solid var(--hairline);
  border-radius: 10px;
  outline: none;
}

.textarea:active {
  border-color: var(--hairlineDark);
}

.textareaError {
  border-color: var(--red);
}

.label {
  font-weight: 500;
  color: var(--midGrey);
  margin-bottom: 1rem;
}

.item {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin: 0.5rem 0;
}

.radio {
  width: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.radio input {
  vertical-align: middle;
  margin: 0 0.5rem;
}

.error {
  color: var(--red);
  font-weight: 500;
}
