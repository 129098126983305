.search {
  background: var(--white);
  height: 40px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  border: 1px solid var(--lightGrey);
  overflow: hidden;
}

.withFilter {
  padding-right: 2.5rem;
}

.inputWrapper {
  flex: 1;
  overflow: hidden;
}

.search input {
  outline: none;
  background: transparent;
  border: none;
  padding-left: 40px;
  height: 40px;
  font: inherit;
  appearance: none;
  align-self: stretch;
  padding-right: 2.5rem;
  width: 100%;
}

.search input::placeholder {
  color: var(--placeholder);
}

.icon,
.x,
.filtersMenu {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.icon {
  left: 10px;
  pointer-events: none;
}

.icon > span {
  background: #aaa;
}

.x,
.filtersMenu {
  background: var(--white);
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.x {
  right: 0;
  padding-right: 10px;
  cursor: pointer;
}

.withFilter .x {
  right: 2rem;
}

.filtersMenu {
  padding-right: 0.5rem;
  right: 0;
}

.filters {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
  margin-left: 0.5rem;
}

.filterOption {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.filterOptionLabel {
  flex: 1;
}

.underline {
  border: 0 none;
  border-bottom: 1px solid var(--hairline);
  border-radius: 0;
}

.underline .icon {
  left: -4px;
}

.underline input {
  padding-left: 28px;
}

@media (max-width: 939px) {
  .search {
    width: 100%;
  }

  .filters,
  .filtersMenu {
    display: none;
  }

  .withFilter .x {
    right: 0;
  }
}
