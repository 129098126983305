.wrapper {
  margin-bottom: calc(60px + 1rem);
}

.row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 1rem;
}

.row > * {
  flex: 1;
}

.row > *:first-child {
  flex: unset;
}
