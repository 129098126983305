:root {
  --primary: #000;
  --primaryHover: #0f95a9;
  --primaryActive: #27a8bb;
  --primaryLight: #e6f3f5;
  --primaryLightest: #f7fbfc;
  --primaryAlpha3: #00849808;
  --primaryAlpha10: #00849819;
  --primaryAlpha20: #00849833;
  --yellow: #c8b227;
  --yellowLight: #fbf0b7;
  --yellowLightest: #fff8e5;
  --yellowMid: #e4d48d;
  --contrast: var(--yellow);
  --navbarBackground: var(--primaryLightest);
  --navLink: #99c3ca;
  --navLinkHover: #6db3bd;
  --white: #fff;
  --whiteHover: #f6f6f6;
  --whiteActive: #e7e7e7;
  --black: #000;
  --blackHover: #333;
  --blackActive: #555;
  --lightGrey: #e7e7e7;
  --lightestGrey: #f5f5f5;
  --midGrey: #8e8e8e;
  --midGreyHover: #777;
  --midGreyActive: #666;
  --midGreyAlpha10: #8e8e8e19;
  --midGreyAlpha20: #8e8e8e33;
  --dotGrey: #c4c4c4;
  --darkGrey: #666;
  --red: #c50c0c;
  --redAlpha10: #c50c0c19;
  --redAlpha20: #c50c0c33;
  --redAlpha10Opaque: #f9e7e7;
  --redAlpha20Opaque: #f3cece;
  --hairline: var(--lightGrey);
  --hairlineDark: #ddd;
  --hairlineTransparent: #00000018;
  --placeholder: #bbb;

  /* Fonts */
  --fixed: 'Source Code Pro', monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  overflow-x: scroll;
  overscroll-behavior-x: contain;
  font-size: 13px;
}

p a {
  font-weight: 500;
  color: var(--primary);
  text-decoration: none;
}

p a:hover {
  text-decoration: underline;
}

p ul {
  margin: 0.5em 0 0.5em 1.5em;
}

.danger {
  color: var(--red);
  font-weight: 500;
}

.grey {
  color: var(--midGrey);
}

@media print {
  body {
    width: 1000px;
  }
}

@media (max-width: 939px) {
  .hidden-sm {
    display: none;
  }
}

@media (min-width: 940px) {
  .hidden-lg {
    display: none;
  }
}
