.wrapper {
  width: 100%;
  margin: 20px 0 100px;
  padding: 0 40px;
}

.wrapper:not(.fullWidth) {
  max-width: 620px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 939px) {
  .wrapper {
    padding: 0 20px;
  }
}
