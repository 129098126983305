.cardInput {
  margin: 18px 0;
}

.cardInput > label {
  color: var(--midGrey);
  font-weight: 500;
}

.cardSub {
  padding: 1rem;
  border-radius: 10px;
  border: 1px solid var(--hairline);
}
