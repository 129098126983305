.wrapper {
  padding-left: 270px;
}

.wrapper.bannerActive {
  padding-top: 110px;
}

.wrapper .childContent {
  margin-top: 70px;
}

.wrapper.bannerActive .childContent {
  margin-top: 110px;
}

@media (max-width: 939px) {
  .wrapper {
    padding-left: 0;
  }
}
