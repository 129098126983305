.wrapper {
  display: block;
  width: 32px;
  height: 32px;
  max-width: 32px;
  max-height: 32px;
  padding: 4px;
  border-radius: 50%;
  background: transparent;
  transition: background 0.2s;
  margin-left: -4px;
  margin-right: -4px;
  cursor: pointer;
}

.icon {
  display: block;
}

.large {
  width: 40px;
  height: 40px;
  max-width: 40px;
  max-height: 40px;
  padding: 8px;
  margin-left: -12px;
  margin-right: -12px;
}

.wrapper:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.wrapper:active,
.wrapper.active {
  transition: none;
  background-color: rgba(0, 0, 0, 0.2);
}

.loading {
  pointer-events: none;
}

/* Grey */
.color-grey:hover {
  background: #8e8e8e26;
}

.color-grey:active,
.color-grey.active {
  background: #8e8e8e4d;
}

/* Light Grey */
.color-lightGrey:hover {
  background: #bbbbbb26;
}

.color-lightGrey:active,
.color-lightGrey.active {
  background: #bbbbbb4d;
}

/* Black */
.color-black {
  background: var(--black);
}

.color-black:hover,
.color-black:focus {
  background: var(--blackHover);
}

.color-black:active,
.color-black.active {
  background: var(--blackActive);
}

/* Blue */
.color-blue:hover {
  background: #0038ff26;
}

.color-blue:active,
.color-blue.active {
  background: #0038ff4d;
}
