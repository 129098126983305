.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 0 4rem;
  position: sticky;
  left: 0;
  gap: 0.5rem;
}

.wrapper button {
  appearance: none;
  border: 0px none;
  background: transparent;
  font: inherit;
  font-size: 24px;
  line-height: 1rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.wrapper button:hover {
  color: var(--blackHover);
}

.wrapper button[disabled] {
  pointer-events: none;
}
