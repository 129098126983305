.banner {
  background: #0038ff;
  color: #fff;
  font-weight: 600;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 1002;
}

.banner a {
  color: #fff;
}

.banner a:hover {
  text-decoration: none;
}
