.wrapper {
  position: fixed;
  top: 0;
  left: 270px;
  right: 0;
  bottom: 0;
  overflow: scroll;
}

.chart {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.header {
  height: 60px;
  margin-top: 1rem;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  margin-bottom: 1rem;
  background: var(--white);
  box-shadow: 0 1px var(--hairlineTransparent);
}

.headerSub {
  height: 100%;
  padding: 0.5rem 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.spacer {
  flex: 1;
}

.query {
  position: sticky;
  left: 0;
  padding: 0 40px;
}

.form {
  background: var(--lightestGrey);
  padding: 1rem;
  border-radius: 10px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 1rem;
}

.input {
  flex: 1;
}

.title {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.titleSub {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.actions {
  margin: 0 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.searchIcon {
  display: none;
}

@media (max-width: 939px) {
  .wrapper {
    left: 0;
  }

  .headerSub,
  .query {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .headerSub {
    padding-left: 50px;
  }

  .header {
    margin-top: 0;
  }

  .actions {
    display: none;
  }

  .search {
    width: 0;
    max-width: 0;
    opacity: 0;
  }

  .searchIcon {
    display: block;
  }

  .searchActive .search {
    width: 100%;
    max-width: unset;
    opacity: 1;
  }

  .searchActive .searchIcon,
  .searchActive .title {
    display: none;
  }
}
