.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow-y: scroll;
}

.inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: min-content;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.inner p {
  margin: 4px 0 16px;
}

@media (max-width: 939px) {
  .wrapper {
    position: unset;
  }
}
