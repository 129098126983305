.success {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.success p {
  font-size: 15px;
  margin: 0.5rem 0 2rem;
}

.emoji {
  font-size: 24px;
  vertical-align: middle;
  position: relative;
  top: -2px;
}
