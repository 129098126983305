.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 2rem;
}

.content {
  margin: 2rem 0 4rem;
}

.content h1 {
  margin: 0 0 0.5rem;
}

.content p {
  font-size: 15px;
}
