.emptyState {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px;
  text-align: center;
}

.emptyState:not(.black) {
  color: var(--midGrey);
}

.emptyState.full {
  padding-left: 0;
  padding-right: 0;
  color: unset;
}

.emptyState h2 {
  font-weight: 600;
  margin-bottom: 16px;
}

.emptyState h2:last-child {
  margin-bottom: 0;
}

.emptyState:not(.tight) p {
  font-size: 15px;
}

.tight {
  padding: 20px;
}

.emptyState p + * {
  margin-top: 1rem;
}

@media (max-width: 939px) {
  .emptyState {
    padding: 20px 0;
  }
}
