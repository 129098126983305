.row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.5rem;
  margin: 18px 0;
}

.name {
  flex: 1;
}
