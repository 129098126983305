.error {
  background: var(--redAlpha10Opaque);
  color: var(--red);
  padding: 8px 16px;
  margin: 18px 0;
  font-weight: 500;
  border-radius: 10px;
  text-align: center;
  white-space: pre-line;
}
