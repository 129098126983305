.wrapper {
  position: relative;
  user-select: none;
}

.avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: url('./default-avatar.png') no-repeat center;
  background-size: cover;
  cursor: pointer;
  margin-right: 8px;
}

.menuItem {
  padding: 8px 0;
  display: block;
  color: var(--primary);
  text-decoration: none;
}

a.menuItem {
  cursor: pointer;
  font-weight: 600;
}

.menu hr {
  margin: 8px 0;
  border: none;
  border-bottom: 1px solid var(--hairline);
}

.menuFooter {
  padding: 8px 0;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  font-size: 13px;
  color: var(--navLink);
}

.menuFooter a {
  color: var(--navLink);
  font-weight: 500;
  text-decoration: none;
}

.menuFooter a:hover {
  text-decoration: underline;
  color: var(--navLinkHover);
}

.menuFooterDivider {
  margin: 0 0.5rem;
}

.email {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.trigger {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 500;
  padding: 10px 32px;
  cursor: pointer;
  transition: background 0.2s;
  color: var(--primary);
}

.trigger:hover {
  background: var(--primaryAlpha3);
}

.trigger:active,
.triggerExpanded {
  background: var(--primaryAlpha10);
}

.switchOrg {
  font-size: 13px;
}

.switchOrg label {
  color: var(--primary);
}

.switchOrg select {
  background-color: var(--white);
  color: var(--black);
}

@media (max-width: 939px) {
  .menu {
    margin: 30px 0;
    padding: 0 40px;
  }
}

@media (min-width: 940px) {
  .backdrop {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }

  .menu {
    position: absolute;
    left: 100%;
    bottom: -10px;
    margin-top: 16px;
    margin-left: 4px;
    background: var(--navbarBackground);
    font-size: 15px;
    padding: 8px 0;
    width: 320px;
    border-radius: 6px;
    z-index: 1;
    box-shadow: 0 0 0 1px var(--primaryAlpha3), 0 8px 24px rgba(0, 0, 0, 0.15),
      0 4px 8px rgba(0, 0, 0, 0.08);
  }

  .menuItem {
    padding: 12px 28px;
  }

  a.menuItem:hover {
    background: var(--primaryAlpha3);
  }

  a.menuItem:active {
    background: var(--primaryAlpha10);
  }

  .menuFooter {
    padding: 8px 28px;
  }
}
