.button {
  font: inherit;
  background: #fff;
  color: #000;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  height: 40px;
  font-size: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 16px;
  transition: all 0.2s;
  position: relative;
  border-radius: 10px;
  font-weight: 600;
  text-decoration: none;
  border: 0px none;
  outline: none;
  cursor: pointer;
  white-space: nowrap;
  text-align: center;
}

.block {
  flex: 1;
  width: 100%;
  justify-content: center;
}

.icon {
  background-color: #000;
  margin-right: -4px;
  margin-left: -4px;
}

.content {
  flex: 1;
}

.icon + .content {
  margin-left: 12px;
}

.button:hover,
.button:focus {
  background-color: #f1f1f1;
}

.button:active {
  background-color: #e1e1e1;
  transition: background-color 0s;
}

/* Sizes */

.xs {
  height: 32px;
  padding: 0 10px;
  font-size: 13px;
}

.small {
  height: 36px;
  padding: 0 14px;
  font-size: 13px;
}

.large {
  height: 64px;
  padding: 0 18px;
}

/* Primary */

.primary {
  background-color: var(--primary);
  color: var(--white);
  box-shadow: inset 0 0 0 100px transparent;
  transition: all 0.2s;
}

.primary .icon {
  background-color: var(--white);
}

.primary:hover,
.primary:focus {
  background-color: var(--primary);
  box-shadow: inset 0 0 0 100px rgba(255, 255, 255, 0.1);
}

.primary:active {
  background-color: var(--primary);
  box-shadow: inset 0 0 0 100px rgba(255, 255, 255, 0.2);
}

/* Danger */

.danger {
  color: var(--red);
}

/* Disabled */

.disabled {
  background: transparent !important;
  color: #bbb;
  box-shadow: inset 0 0 0 1px #bbbbbb8a !important;
  pointer-events: none;
}

.disabled .icon {
  background: #bbb;
}

/* Text */

.text {
  background: transparent !important;
  color: var(--midGrey);
  box-shadow: none;
  font-weight: 500;
  padding-left: 0;
  padding-right: 0;
}

.text .icon {
  background-color: var(--midGrey);
}

.text:hover,
.text:focus {
  color: var(--midGreyHover);
}

.text:active {
  color: var(--midGreyActive);
}

.text.disabled {
  box-shadow: none !important;
  opacity: 0.5;
}

.text.primary {
  color: var(--primary);
}

.text.primary .icon {
  background-color: var(--primary);
}

/* Loader */

.loader {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 15px;
}

.group {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.group > * + * {
  margin-left: 0.5rem;
}

.align-none {
  /* Nothing here */
}

.align-left {
  justify-content: flex-start;
}

.align-right {
  justify-content: flex-end;
}

.align-spread {
  justify-content: space-between;
}
