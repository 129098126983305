.portal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  width: 100%;
  max-width: 480px;
  transform: translateX(480px);
  box-shadow: none;
  background: var(--white);
  transition: transform 0.2s;
  display: flex;
  flex-direction: column;
  z-index: 1000;
}

.visible {
  transform: translateX(0);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
}

.content {
  flex: 1;
  position: relative;
}

.contentSub {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
}

.footerContainer {
  background: var(--white);
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.1);
}

.modal {
  margin: 35px 0 40px;
  padding: 0 40px;
  z-index: 1;
  width: 100%;
  max-width: 600px;
  position: relative;
}

.footerWrapper {
  padding: 20px 40px;
}

.footerAdditional {
  margin-top: 1rem;
}

.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.footer > * + * {
  margin-left: 0.5rem;
}

.closeButton {
  position: absolute;
  width: 25px;
  height: 25px;
  right: 30px;
  top: 30px;
  font-size: 24px;
  font-weight: 100;
  z-index: 1;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  color: transparent;
  overflow: hidden;
  text-indent: -10000px;
}

.closeButton:before,
.closeButton:after {
  content: '';
  display: block;
  position: absolute;
  top: 12px;
  left: 0;
  right: 0;
  border-bottom: 1px solid var(--black);
}

.closeButton:before {
  transform: rotate(-45deg);
}

.closeButton:after {
  transform: rotate(45deg);
}

.header {
  display: flex;
  flex-direction: row;
}

* + .headerContent {
  margin-left: 1rem;
}

@media (max-width: 939px) {
  .modal {
    margin: 20px 0;
    padding: 0 16px;
  }

  .closeButton {
    right: 22px;
    top: 22px;
  }
}
