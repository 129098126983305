.body {
}

.wrapper {
  max-width: 540px;
  margin: 0 auto;
  padding: 20px 20px 60px;
}

.wide {
  max-width: 780px;
}

.brand {
  text-indent: -10000px;
  color: transparent;
  overflow: hidden;
  height: 20px;
  background: url('../Navbar/logo.svg') no-repeat center;
}

.links {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.links > * + *:before {
  content: '•';
  margin: 0 10px;
}

.children {
  position: relative;
}

.sidebar {
  position: absolute;
  left: 100%;
  margin-left: 28px;
  top: 30px;
  width: 220px;
}

.title {
  text-align: center;
  margin-top: 1.5rem;
  font-weight: normal;
  font-size: 1rem;
  color: var(--midGrey);
}

@media (min-width: 940px) {
  .document {
    height: 100%;
  }

  .body {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .body > div {
    display: none;
  }

  .body > div[id='root'] {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: min-content;
  }
}
